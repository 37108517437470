export const theme = {
  screens: {
    sm: "500px",

    md: "768px",

    lg: "1100px",

    xl: "1350px",

    "2xl": "1536px",
  },
  container: {
    center: true,
    padding: "2rem",
    screens: {
      "2xl": "1400px",
    },
  },
  fontFamily: {},
  extend: {
    keyframes: {
      "accordion-down": {
        from: { height: "0" },
        to: { height: "var(--radix-accordion-content-height)" },
      },
      "accordion-up": {
        from: { height: "var(--radix-accordion-content-height)" },
        to: { height: "0" },
      },
    },
    animation: {
      "accordion-down": "accordion-down 0.2s ease-out",
      "accordion-up": "accordion-up 0.2s ease-out",
      "spin-slow": "spin 2s linear infinite",
    },
    backdropBlur: {
      DEFAULT: "5px",
    },
    textColor: {
      light: "#ced6e2",
      "gold-light": "#eac9b1",
      primary: "#FFFFFF",
      dark: "#020C1B",
      inputPlaceholder: "#616162",
      danger: "#ff6c6c",
    },
    backgroundColor: {
      background: "#020C1B",
      "header-background": "#091627",
      "background-radial-gradient":
        "radial-gradient(92.78% 92.78% at 27.45% 10%,#afc6d4 0%,#afc6d400 100%)",
      input: "#091627",
      "input-dark": "#050E19",
      "button-light": "#3A4552",
      "button-dark": "#050E19",
      value: {
        good: "#189348",
        average: "#C97E0F",
        bad: "#BF3415",
      },
    },
    colors: {
      "primary-gold": "#DCA37A",
      "primary-gold-dark": "#ab770f",
      button: {
        pill: "#050e19",
        "pill-border": "#3a4552",
        "pill-text": "#707d85",
        "pill-selected": "#afc6d4",
        "pill-selected-text": "#050e19",
      },
      card: {
        "background-dark": "#050E19",
        "background-light": "#091627",
        "border-light": "#ffffff",
        "border-dark": "#020C1B",
        "border-gold": "#CB9872",
        "linear-gradient": {
          "primary-bright":
            "linear-gradient(93deg, #EBCAB2 0.63%, #DCA37A 99.87%), #DCA37A",
          default:
            "linear-gradient(90deg, rgba(175, 198, 212, 0.15) 0.26%, rgba(175, 198, 212, 0.00) 66.14%), #050E19",
          bronze:
            "linear-gradient(90deg, rgba(235, 153, 113, 0.15) 0.26%, rgba(235, 153, 113, 0.00) 66.14%), #050E19",
          silver:
            "linear-gradient(90deg, rgba(151, 179, 196, 0.15) 4.81%, rgba(255, 255, 255, 0.00) 66.13%), #050E19",
          gold: "linear-gradient(90deg, rgba(220, 163, 122, 0.15) 0.26%, rgba(220, 163, 122, 0.00) 66.14%), #050E19",
          "gold-bright":
            "linear-gradient(90deg, rgba(220, 163, 122, 0.15) 0%, rgba(220, 163, 122, 0.08) 100%), #121214",
          titanium:
            "linear-gradient(90deg, rgba(23, 67, 101, 0.15) 0.26%, rgba(23, 67, 101, 0.00) 66.14%), #050E19",
          platinum:
            "linear-gradient(90deg, rgba(178, 178, 186, 0.15) 0.26%, rgba(38, 38, 41, 0.15) 66.14%), #050E19",
        },
      },
      pin: {
        future: "#DCA37A",
        past: "#7A84DC",
      },
    },
  },
};
